<template lang="pug">
.SingaSearchInput(:class="size")
	.search-right(v-if="inputString" @click.stop.prevent="clearSearch(true)")
		.esc {{ t('search.esc') }}
		SingaIcon(icon="close-outline" size="large")
	SingaField
		SingaInput(ref="input" rounded icon="search-outline" :placeholder="placeholderText" v-model="inputString" @focus="onFocus" @keydown.esc="clearSearch(true)")
</template>

<script setup lang="ts">
import { nextTick } from 'vue'
import { storeToRefs } from 'pinia'

const searchStore = useSearchStore()
const { t } = useI18n()
const { isLoggedIn } = useAuth()

const { getSearchTerm } = storeToRefs(searchStore)

const route = useRoute()

const props = defineProps({
	size: {
		type: String,
		default: '',
		required: false
	},
	placeholderText: {
		type: String,
		default: '',
		required: false
	}
})
const searchString: any = (route.params.search && route.params.search.length > 0) ? ref(route.params.search) : ref('')
const searchTerm = searchString.value.slice(7) || ''

const inputString = ref(searchTerm || '')

const { placeholderText } = toRefs(props)

const input = ref()
const previousRoute = ref()
const localePath = useLocalePath()
const timeoutId = ref(-1)

const { segmentEvent } = useSegment()

const emit = defineEmits(['filterChanged', 'focus', 'focusout'])

const clearFocus = () => {
	// Temporary hack (🤞) to prevent a warning from useRoute
	if (useNuxtApp()._processingMiddleware) {
		setTimeout(() => {
			emit('focusout')
		}, 50)
	} else {
		emit('focusout')
	}
}

watch(inputString, (value) => {
	if (inputString.value === '') {
		return
	}
	if (timeoutId.value > -1) {
		clearTimeout(timeoutId.value)
		timeoutId.value = -1
	}
	timeoutId.value = window.setTimeout(() => {
		searchString.value = inputString.value
		if (isLoggedIn.value) {
			segmentEvent('Venue Search Initiated')
		}
		navigateTo({ path: localePath('/karaoke-near-me/search'), query: { term: value } })
	}, 1000)
})

watch(() => getSearchTerm.value, () => {
	if (getSearchTerm.value) {
		inputString.value = getSearchTerm.value
	}
})

const onFocus = function () {
	previousRoute.value = route.fullPath
	emit('focus')
}

const clearSearch = function (focusout: boolean) {
	if (focusout) {
		clearFocus()
	}
	inputString.value = ''
	navigateTo({ path: previousRoute.value ? previousRoute.value : localePath('/karaoke-near-me/') })
}

const clickedOutside = function (e: any) {
	if (input?.value?.$el !== null && input?.value?.$el.contains(e.target)) {
		emit('focus')
	} else {
		clearFocus()
	}
}

onUpdated(async () => {
	await nextTick()
	if (import.meta.client) {
		window?.addEventListener('click', clickedOutside)
	}
})

onBeforeUnmount(() => {
	if (import.meta.client) {
		window?.removeEventListener('click', clickedOutside)
	}
})
</script>

<style lang="sass" scoped>
.SingaSearchInput
	width: 100%
	position: relative
	height: 48px
	&:not(:focus-within)
		.esc
			display: none
	:deep(.control .icon)
		width: 32px
		height: 32px
		top: initial
		.base-icon
			width: 32px
			height: 32px
	:deep(input)
		border-color: transparent !important
		text-align: center
		@include font(basier, medium)
		padding-left: 48px !important
		height: 100%
.search-right
	display: flex
	align-items: center
	position: absolute
	right: 12px
	z-index: 1000
	height: 100%
	cursor: pointer
	.icon
		color: $color-grey-50
	.esc
		background-color: $transparent-white-8
		color: $transparent-white-40
		padding: 4px 10px
		border-radius: $radius-default
		margin-right: $spacing-16
		display: none
		@media (min-width: $tablet)
			display: block
.field, .control
	height: 100%

@media (min-width: $tablet)
	.SingaSearchInput:not(.is-small)
		height: 56px
</style>
